<template>
  <van-overlay z-index="99" :show="showModel" @click="show = false">
    <div class="flex items-center justify-center h-full" @click.stop>
      <div>
        <img
          @click="showModel = false"
          class="w-32px h-32px ml-auto"
          src="~@/assets/new/close-icon.png"
          alt=""
          srcset=""
        />
        <div class="bg-white w-286px rounded-24px p-24px mt-20px relative">
          <div class="absolute right-0 top-0">
            <img class="w-122px h-115px" src="~@/assets/new/invite-bg.png" alt="" srcset="">
          </div>
          <div class="text-[#333] text-28px">{{$t('inputInvite')}}</div>
          <div class="text-[#333] text-28px mt-6px">{{$t('jhzh')}}</div>
          <div class="mt-24px">
            <div class="border border-[#00000033] rounded-16px relative" :class="{error:error}">
              <van-field class="rounded-16px" v-model="val" label-width="0" :placeholder="$t('inputInvite')"></van-field>
              <div v-if="error" class="text-[#F83232] absolute -bottom-30px w-full text-center">{{$t('jhmyw')}}</div>
            </div>
          </div>
          <div class="text-[#333333] text-center">
            <div class="h-20px"></div>
            
            <template v-if="success">
              <img class="w-54px h-43px mx-auto mt-16px" src="~@/assets/new/inivte-success.png" alt="" srcset="">
              <div class="mt-12px text-17px">{{$t('jhcg')}}</div>
            </template>
          </div>
          <div class="btn-md btn-bg mt-20px" @click="onSuccess" v-if="success">{{$t('zdl')}}</div>
          <div class="btn-md btn-bg mt-20px" v-else @click="onSubmit">{{$t('ljjc')}}</div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>
<script>
export default {
  props: {
    value:{
      type:String,
      default:''
    },
    show: {
      type: Boolean,
      default: false,
    },
    success:{
      type: Boolean,
      default: false,
    }
  },
  computed: {
    showModel: {
      get() {
        return this.show;
      },
      set(e) {
        if(!e){
          this.value = ''
          // if(this.success) window.location.reload()
        } 
        this.$emit("update:show", e);
      },
    },
    val: {
      get() {
        return this.value;
      },
      set(e) {
        this.$emit("input", e);
      },
    },
  },
  data(){
    return{
      error:false
    }
  },
  methods:{
    onSuccess(){
      this.showModel = false
      // window.location.reload()
    },
    onSubmit(){
      this.error = false
      const re = new RegExp(/^0x[a-fA-F0-9]{40}$/);
      if(!re.test(this.value)) {
        return this.error = true
      }
      this.$emit('submit',this.value);
    }
  }
};
</script>
<style lang="scss" scoped>
.error{
  @apply border-[#F83232];
  // &::after{
  //   contain: '11';
  // }
}
</style>