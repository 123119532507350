<template>
  <div class="home-page">
    <div
      v-if="register == false"
      class="fixed top-0 rounded-b-32px h-130px flex items-center justify-between w-full boxshow bg-[#324e73b3] backdrop-filter !backdrop-blur-20px px-24px z-10"
    >
      <div class="flex items-center">
        <img
          class="w-36px h-36px"
          src="~@/assets/new/01.png"
          alt=""
          srcset=""
        />
        <span class="ml-8px text-text1 text-15px">{{ $t('invite') }}</span>
      </div>
      <div @click="inviteShow = true" class="btn-sm btn-bg">
        {{ $t('gobind') }}
      </div>
    </div>
    <div class="relative">
      <div class="">
        <div class="h-44px w-full"></div>
        <div
          class="flex items-center justify-between h-48px px-24px text-text1"
        >
          <img
            class="w-67px h-32px"
            src="~@/assets/new/logo.png"
            alt=""
            srcset=""
          />
          <img
            class="w-34px h-34px rounded-full"
            src="~@/assets/new/avatar.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="px-24px text-26px font-medium mt-32px">
          <div>{{$t('welcome')}} MATE DAO</div>
          <div class="mt-20px">{{global.account | hiddenAddress}}</div>
        </div>
        <div class="px-24px mt-30px">
          <div
            class="relative rounded-30px bg-gradient-to-l to-hex-[#FF8108] from-hex-[#FFD337] px-26px py-24px"
          >
            <div class="text-14px">{{$t('tzsy')}}</div>
            <div class="flex items-center justify-between">
              <div class="text-30px font-semibold leading-42px">
                {{ earn2 }}
              </div>
              <div
                @click="playwithdrawReward2"
                class="btn-shadow leading-34px bg-gradient-to-br from-hex-[#FF6913] to-hex-[#FB0BED] py-0px px-24px rounded-full text-14px font-medium"
              >
                {{$t('tq')}}
              </div>
            </div>
            <div class="mt-52px text-14px">
              <span>{{$t('head.IPHPrice')}}：</span>
              <span class="font-semibold">{{ jingdu }}</span>
            </div>
          </div>
        </div>
        <div class="mx-24px grid grid-cols-2 mt-20px gap-x-19px">
          <div
            class="border border-[#ffffff0f] rounded-24px box py-40px px-24px"
          >
            <img
              class="w-28px h-28px"
              src="@/assets/new/icon1.png"
              alt=""
              srcset=""
            />
            <div class="opacity-70 mt-20px">{{$t('cjye')}}</div>
            <div class="mt-6px leading-28px text-24px font-semibold">
              {{overReward}}
            </div>
          </div>
          <div
            class="border border-[#ffffff0f] rounded-24px box py-40px px-24px"
          >
            <img
              class="w-28px h-28px"
              src="@/assets/new/icon2.png"
              alt=""
              srcset=""
            />
            <div class="opacity-70 mt-20px">{{$t('rhl')}}</div>
            <div class="mt-6px leading-28px text-24px font-semibold">{{earn3}}‰</div>
          </div>
        </div>
        <div v-if="false" class="mt-24px px-24px">
          <div
            class="border border-[#ffffff0f] rounded-24px bg-[#ffffff1a] px-20px py-22px relative"
          >
            <div>
              <div>
                <div class="flex justify-between">
                  <div class="text-32px font-bold font-Gilroy">
                    <span>{{ earn }}</span
                    ><span class="ml-6px">USDT</span>
                  </div>
                  <img
                    class="w-42px h-27px"
                    src="~@/assets/new/02.png"
                    alt=""
                  />
                </div>
              </div>
              <van-divider dashed></van-divider>
              <div>
                <div class="flex justify-between">
                  <div class="text-32px font-bold font-Gilroy">
                    <span>{{ earn2 }}</span
                    ><span class="ml-6px">USDT</span>
                  </div>
                </div>
                <div class="text-[#ffffff99] text-16px font-Gilroy mt-8px">
                  EARNED
                </div>
                <div class="flex justify-between mt-10px">
                  <div class="text-32px font-bold font-Gilroy">
                    <span>{{ earn3 }} / 1000</span>
                  </div>
                </div>
                <div class="mt-29px">
                  <div
                    class="btn-sm btn-bg w-93px"
                    @click="playwithdrawReward2"
                  >
                    {{ $t('head.get') }}
                  </div>
                </div>
              </div>
              <van-divider dashed></van-divider>
            </div>
          </div>
        </div>
      </div>
      <div v-if="false" class="px-24px mt-22px">
        <div class="flex items-center justify-between">
          <div class="text-21px">{{ $t('coinList') }}</div>
          <div class="text-14px text-[#ffffffb3]">
            {{ $t('head.IPHPrice') }}：1 UNC ~ {{ jingdu }} USDT
            <!-- {{ $t('head.IPHPrice') }}：1U~400 -->
          </div>
        </div>
      </div>
      <div class="mt-22px px-24px" v-if="register">
        <div class="grid grid-cols-2 gap-x-20px mb-20px">
          <div
            :class="{ active: priceActive == item.value }"
            @click="priceActive = item.value"
            v-for="item in priceList"
            :key="item.value"
            class="text-center rounded-4px border border-[#ffffff] border-opacity-20 border-solid h-30px leading-30px text-14px"
          >
            {{ item.lable }}
          </div>
        </div>
        <div v-for="(item, index) in bilist" :key="index">
          <ItemComp
            class="mb-16px"
            :deposit1="deposit1[index]"
            :amountA="amountA[index]"
            :amountB="amountB[index]"
            :Hash="Hash[index]"
            :poolearn="poolearn[index]"
            v-if="item.block && item.show == true"
            :index="index"
            :data="item"
            @stake="onStake"
            @approve="onApprove"
          ></ItemComp>
        </div>
      </div>
    </div>
    <InviteComp
      v-model="yaoqing"
      :success="inviteSuccess"
      :show.sync="inviteShow"
      @submit="onSubmit"
    ></InviteComp>
  </div>
</template>
<script>
import mixin from '@/mixins/index'
import ItemComp from './components/itemComp.vue'
import InviteComp from './components/inviteComp.vue'
import { BigNumber } from 'bignumber.js'
export default {
  components: {
    ItemComp,
    InviteComp,
  },
  data() {
    return {
      inviteSuccess: false,
      inviteShow: false,
      level: '0',
      earn: '0',
      earn2: '0',
      earn3: '0',
      earn4: '0',
      register: false,
      deposit: [],
      bilist: [],
      deposit1: [],
      amountA: [],
      amountB: [],
      Hash: [],
      poolearn: [],
      apr: [],
      yaoqing: '',
      jingdu: '',
      shuru: '',
      totalSupply: 0,
      intervalId: null,
      priceActive: 1,
      overReward:0,
      priceList: [
        {
          value: 1,
          balance: 300,
          lable: '300U',
        },
        {
          value: 2,
          balance: 2000,
          lable: '2000U',
        },
      ],
    }
  },
  computed: {
    currentPrice() {
      return this.priceList.find((e) => e.value == this.priceActive)
    },
  },
  mixins: [mixin],
  created() {
    this.$axios.get(this.listdata + '/pool/list').then((res) => {
      res.data.data.map((item) => {
        // console.log(item, "sssssssssssssssss");
        let list = {}
        list.tokenaddress = item.token0
        list.tokenaddress1 = item.token1
        list.img1 = item.icon0
        list.img2 = item.icon1
        if (item.token1.toLowerCase() == this.pool.iph.toLowerCase()) {
          // list.img2 = IPH
        }
        if (item.token0.toLowerCase() == this.pool.iph.toLowerCase()) {
          // list.img1 = IPH
        }
        if (item.name0 == item.name1) {
          list.title = item.name0
        } else {
          list.title = item.name0 + '-' + item.name1
        }
        list.pid = item.pid
        list.deposit = item.name0 + '-' + item.name1
        list.earn = 'UNC'
        list.tokenname = item.name0
        list.tokenname1 = item.name1
        // list.suanli = item.maxWeight / 100 + 'X'
        list.suanli = item.maxWeight + '%'
        list.block = true
        list.show = item.showp
        list.decimals0 = item.decimals0
        list.decimals1 = item.decimals1
        this.bilist.push(list)
      })
      // console.log(this.bilist)
    })
  },
  watch: {
    'global.account'() {
      this.yaoqing = window.location.hash.substring(3)
      if (window.location.hash.substring(3) == '') {
        this.yaoqing = ''
      }

      this.USDT_contract = new this.global.web3.eth.Contract(
        this.poolabi.erc20abi,
        this.pool.USDT
      )

      this.POOL5_contract = new this.global.web3.eth.Contract(
        this.poolabi.pool_abi5,
        this.pool.pool5
      )

      this.IPHInvitation_contract = new this.global.web3.eth.Contract(
        this.poolabi.iphInvitation_abi,
        this.pool.iphInvitation
      )
      this.MTC4Pool2_contract = new this.global.web3.eth.Contract(
        this.poolabi.MTC4Pool2_abi,
        this.pool.MTC4Pool2
      )
      this.intervalId = setInterval(() => {
        this.getOverRewards()
        this.getearn()
        this.registerbool() //加载数据函数
      }, 5000)
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
    this.intervalId = null
  },
  methods: {
    getOverRewards(){
      this.POOL5_contract.methods.getOverRewards(this.global.account).call().then(res=>{
        this.overReward = (res / 1e18).toFixed(4)
      })
    },
    onStake(e) {
      this.POOL5_contract.methods
        .deposit(e.pid, this.currentPrice.value)
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.$toast('SUCCESS')
        })
        .catch((err) => {
          this.$toast(err)
        })
    },
    onApprove(e) {
      const price = (this.currentPrice.balance * 10 * 1e18).toFixed(0)
      const Contract = new this.global.web3.eth.Contract(
        this.poolabi.erc20abi,
        e.tokenaddress
      )
      Contract.methods
        .approve(
          this.pool.pool5,
          this.global.web3.utils.numberToHex(BigNumber(price))
        )
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.$toast('APPROVE SUCCESS')
        })
        .catch(() => {
          this.$toast('APPROVE ERROR')
        })
    },
    onSubmit(e) {
      this.IPHInvitation_contract.methods
        .register(e)
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.inviteSuccess = true
        })
        .catch((err) => {
          this.$toast(err)
        })
    },

    registerbool() {
      // console.log(this.IPHInvitation_contract);
      this.IPHInvitation_contract.methods
        .isExists(this.global.account)
        .call()
        .then((res) => {
          // console.log(res);
          this.register = res
        })
    },
    getearn() {
      this.POOL5_contract.methods
        .getOverRewards(this.global.account)
        .call()
        .then((res) => {
          this.earn = (res / 1e18).toFixed(4)
        })

      this.POOL5_contract.methods
        .getPrice(this.pool.iph, this.pool.USDT)
        .call()
        .then((res) => {
          this.jingdu = (res[1] / 1e18).toFixed(4)
        })

      this.POOL5_contract.methods
        .getRewards(this.global.account)
        .call()
        .then((res) => {
          this.earn2 = (res / 1e18).toFixed(4)
        })

      this.POOL5_contract.methods
        .getUserRelease(this.global.account)
        .call()
        .then((res) => {
          this.earn3 = res
        })
    },

    playwithdrawReward2() {
      if (this.register == false) return this.$toast(this.$t('head.weizhuce'))
      this.POOL5_contract.methods
        .withdrawReward()
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.$toast('SUCCESS')
          this.$router.go(0)
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.boxshow {
  box-shadow: 0px 10px 40px #00000040;
}

.active {
  @apply bg-gradient-to-r from-[#FF8108] to-[#FFD337];
}

.home-page {
  background-image: url('~@/assets/new/home-bg.png');
  background-repeat: no-repeat;
  background-size: 775px 775px;
  min-height: 100%;
}

.btn-shadow {
  box-shadow: 0px 4px 16px 0px #ef58c0;
}

.box {
  backdrop-filter: saturate(180%) blur(2px); //毛玻璃效果
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.21),
    rgba(255, 255, 255, 0.1)
  );
}
</style>
