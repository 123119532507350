<template>
  <div
    class="border border-[#ffffff0f] border-solid rounded-24px p-20px relative overflow-hidden"
  >
    <div
      :style="{ background: color.c1 }"
      class="absolute w-144px h-126px filter blur-50px right-0 top-0"
    ></div>
    <div
      :style="{ background: color.c2 }"
      class="absolute w-98px h-103px filter blur-50px left-30px bottom-21px"
    ></div>
    <div class="">
      <div class="flex">
        <div class="w-56px h-56px">
          <img
            class="w-full h-full rounded-full"
            :src="data.img1"
            alt=""
            srcset=""
          />
        </div>
        <div class="ml-16px">
          <div class="text-17px">{{ data.tokenname }}</div>
          <div class="inline-block mt-8px">
            <div
              class="border border-solid border-[#ffffff33] rounded-200px h-20px flex items-center px-10px text-13px"
            >
              {{ data.suanli }}
            </div>
          </div>
        </div>
      </div>
     
      <div
        class="mt-20px grid grid-cols-2 gap-x-25px"
        :style="{ color: color.c3 }"
      >
        <div
          @click="onApprove"
          class="bg-white bg-opacity-15 rounded-full text-[#FF8108] h-34px leading-34px flex-1 text-center z-2 text-15px font-semibold"
        >
          {{ $t('head.aa7') }}
        </div>
        <div
         @click="onStake"
          class="bg-gradient-to-r from-[#FF8108] to-[#FFD337] rounded-full h-34px leading-34px flex-1 text-center z-2 text-15px font-semibold text-white"
        >
          {{ $t('head.stake') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: () => {},
    },
    deposit1: {
      type: [String, Number],
      default: '',
    },
    amountA: {
      type: [String, Number],
      default: '',
    },
    amountB: {
      type: [String, Number],
      default: '',
    },
    Hash: {
      type: [String, Number],
      default: '',
    },
    poolearn: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      colors: [
        {
          c1: '#CC45DA33',
          c2: '#407CDE99',
          c3: '#E43BD9',
        },
        {
          c1: '#2ab08733',
          c2: '#1783ea66',
          c3: '#FF6341',
        },
        {
          c1: '#4414cb33',
          c2: '#4fc0ff66',
          c3: '#484BDC',
        },
        {
          c1: '#cb141433',
          c2: '#cc61ff66',
          c3: '#FF1B43',
        },
      ],
    }
  },
  computed: {
    color() {
      return this.colors[this.index % this.colors.length]
    },
  },
  methods: {
    onStake() {
      this.$emit('stake', this.data)
    },
    onApprove() {
      this.$emit('approve', this.data)
    },
  },
}
</script>
